/*
 * @Author       : lenchen
 * @Date         : 2020-09-02 18:05:16
 * @LastEditors  : lenchen
 * @LastEditTime : 2021-02-02 14:47:49
 * @FilePath     : \ihub\src\config.js
 * @Description  : site const
 */
export default {
  ROLES: {
    1: "合作成员",
    2: "合作PM(合作商)",
    3: "项目PM(腾讯)",
  },
  TASK: {
    TGTEST: {
      TIMER: 30 * 1000,
    },
  },
  API: {
    AXIOS_TIMEOUT: 250000,
    SUCCESS_CODE: [200, 1, -70005, -90020, -10003, -50040, -50054],
    ERROR_CODE: [-10001, -10000, -10002],
    NO_MODIFY_AUTH_CODE: [-80003],
  },
};
