import router from "./router";
import store from "./store";
import { MessageBox } from "element-ui";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";
NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ["/oauth", "/oauth2", "/verifyemail", "/redirect", '/wiki', '/home', '/learn','/awards2024',"/success"]; // no redirect whitelist
// , '/document', '/home', '/videoSchool'

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  document.title = getPageTitle(to.meta.title);
  const hasToken = getToken();
  if (hasToken) {
    store.dispatch("user/setIsLogin");
    if (whiteList.some((w) => to.path.includes(w))) {
      next();
      NProgress.done();
    } else {
      const hasAuth = store.getters.auth && store.getters.auth.length > 0;
      if (hasAuth) {
        next();
      } else {
        try {
          const { auth, userStat } = await store.dispatch("user/getInfo");
          if ([1, 2].includes(userStat)) {
            // 删除、冻结
            next("/approve");
            // 待签署、重复签署
          } else if ([4, 8].includes(userStat)) {
            next({
              ...to,
              path: "/apply",
            });
          }
          else {
            const { role } = store.getters;
            const accessRoutes = await store.dispatch(
              "permission/generateRoutes",
              [auth, role]
            );
            router.addRoutes(accessRoutes);
            next({ ...to, replace: true });
          }
        } catch (error) {
          MessageBox.alert(error.message || error.msg || "Has Error", {
            type: "error",
          }).then(async () => {
            await store.dispatch("user/logout");
            next(`/home?login=0&redirect=${to.path}`);
            NProgress.done();
          });
        }
      }
    }
  } else {
    if (whiteList.some((w) => to.path.includes(w))) {
      next();
    } else {
      await store.dispatch("user/logout"); // 清空本地cookie数据
      next(
        `/oauth?app_id=5012568a421eeb0b&redirect_url=${location.origin}/`
      );
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
