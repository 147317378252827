import request from "@/utils/request";

export function getProjectDetail(data) {
  return request({
    url: "/api?c=project&a=getProjectDetail",
    method: "post",
    data,
  });
}

export function getPlayTag(data) {
  return request({
    url: "/api?c=project&a=getPlayTag",
    method: "post",
    data,
  });
}