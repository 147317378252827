import request from "@/utils/request";

// https://console-docs.apipost.cn/preview/4b7ee029c8760e2f/e220dbee4465a69e?target_id=71d32e6b-e76a-4411-b6de-aad1166a2c90 
/**
 * 标签列表
 * @param {*} params 
 * @returns 
 */
export function getVideoTagList(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=video&ac=tag",
        method: "get",
        params
    });
}

/**
 * 视频学堂列表
 * @param {*} params 
 * @returns 
 */
export function getVideoSchoolList(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=video&ac=videoList",
        method: "get",
        params
    });
}

/**
 * 视频学堂搜索列表
 * @param {*} params 
 * @returns 
 */
export function keywordSearch(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=video&ac=search&page=1&limit=5",
        method: "get",
        params
    });
}

/**
 * 视频学堂详情
 * @param {*} params 
 * @returns 
 */
export function getVideoSchoolDetail(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=video&ac=video&id=1",
        method: "get",
        params
    });
}

/**
 * 视频学堂更新播放量
 * @param {*} params 
 * @returns 
 */
export function addviews(data) {
    return request({
        url: "/community/custom.php?module=doc&mod=video&ac=addView",
        method: "post",
        data
    });
}

/**
 * 视频学堂举报评论
 * @param {*} params 
 * @returns 
 */
export function report(params) {
    return request({
        url: "/community/misc.php?mod=report&relationType=videoPost",
        method: "post",
        params
    });
}

/**
 * 视频学堂上传图片
 * @param {*} params 
 * @returns 
 */
export function uploadImage(file) {
    const formData = new FormData()
    formData.append("file",file)
    return request({
        url: "/community/custom.php?module=doc&mod=video&ac=uploadImage",
        method: "post",
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
    });
}

/**
 * 视频学堂评论列表
 * @param {*} params 
 * @returns 
 */
export function getVideoPostList(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=video&ac=postList",
        method: "get",
        params
    });
}

/**
 * 视频学堂添加评论
 * @param {*} params 
 * @returns 
 */
export function addPost(data) {
    return request({
        url: "/community/custom.php?module=doc&mod=video&ac=addPost",
        method: "post",
        data
    });
}

/**
 * 视频学堂点赞
 * @param {*} params 
 * @returns 
 */
export function like(data) {
    return request({
        url: "/community/custom.php?module=doc&mod=video&ac=like",
        method: "post",
        data
    });
}

/**
 * 视频学堂回复列表
 * @param {*} params 
 * @returns 
 */
export function replyList(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=video&ac=replyList&limit=3",
        method: "get",
        params
    });
}

/**
 * 视频学堂推荐列表
 * @param {*} params 
 * @returns 
 */
export function recommendList(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=video&ac=recommendList",
        method: "get",
        params
    });
}
