import {
  getToken,
  setToken,
  removeToken,
  getCookie,
  setCookie,
  removeCookie,
} from "@/utils/auth";
import { resetRouter } from "@/router";
import { getMemberByUser, getUserAuth, login, getAccountInfo, inviteUser,heartbeat } from "@/api/user";
import { getPlayTag } from "@/api/project";
import Vue from 'vue'

const vm = new Vue();
const state = {
  limitTime: 120000,
  token: getToken(),
  qrcodeUrl: "",
  cookie: "",
  email: "",
  enName: "",
  avatar: localStorage.getItem('userAvatar'),
  name: localStorage.getItem('userName'),
  uid: getCookie("uid"),
  username: getCookie("username"),
  introduction: "",
  roles: [],
  role: 0,
  roleName: "",
  QQ: "",
  ID: "",
  auth: [],
  companys: [],
  products: [],
  allProducts: [],
  needBindRtx: false,
  isAdmin: false,
  logined: false,
  userStat: "",
  options: {},
  accountInfo: {},
};

const mutations = {
  SET_ID: (state, ID) => {
    state.ID = ID;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_QRCODEURL: (state, qrcodeUrl) => {
    state.qrcodeUrl = qrcodeUrl;
  },
  SET_ADMIN: (state, isAdmin) => {
    state.isAdmin = isAdmin;
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, name) => {
    state.name = name;
    localStorage.setItem('userName', name)
  },
  SET_UID: (state, name) => {
    state.uid = name;
  },
  SET_USERNAME: (state, name) => {
    state.username = name;
  },
  SET_EN_NAME: (state, name) => {
    state.enName = name;
  },
  SET_QQ: (state, QQ) => {
    state.QQ = QQ;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
    localStorage.setItem('userAvatar', avatar)
  },
  SET_EMAIL: (state, email) => {
    state.email = email;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_ROLE: (state, role) => {
    state.role = role;
  },
  SET_ROLE_NAME: (state, roleName) => {
    state.roleName = roleName;
  },
  SET_AUTH: (state, auth) => {
    state.auth = auth;
  },
  SET_COMPANYS: (state, companys) => {
    state.companys = companys;
  },
  SET_PRODUCTS: (state, products) => {
    state.products = products;
  },
  SET_ALL_PRODUCTS: (state, products) => {
    state.allProducts = products;
  },
  SET_BINDING_RTX: (state, need) => {
    state.needBindRtx = need;
  },
  SET_LOGINED: (state, logined) => {
    state.logined = logined;
  },
  SET_OPTIONS: (state, options) => {
    state.options = options
  },
  SET_COOKIE: (state, cookie) => {
    state.cookie = cookie;
  },
  SET_USERSTAT: (state, userStat) => {
    state.userStat = userStat;
  },
  SET_ACCOUNT_INFO: (state, accountInfo) => {
    state.accountInfo = accountInfo;
  },
};

const actions = {
  // user login
  login({ commit, dispatch }, params) {
    // const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login(params)
        .then(async (res) => {
          if (res.ret === 1) {
            const { data } = res;
            setToken(data.ticket);
            commit("SET_TOKEN", data.ticket);
            commit("SET_QRCODEURL", data.qrcodeUrl);
            const infoRes = await getAccountInfo();
            if (infoRes?.code === 200) {
              const { avatar, nickname, uid, username } = infoRes?.data ?? {}
              await dispatch(
                "user/setUserInfo",
                { ...data, id: data.userId, avatar, nickname, uid, username },
                { root: true }
              );
              localStorage.setItem('userInfo', JSON.stringify({...infoRes?.data,loginData:data}));
              vm.$aegis?.setConfig({
                uin: data.userId ?? 999
              });
            } else if (infoRes?.code === -102) {
              const newDate = new Date(infoRes?.data?.expire_time * 1000 + 8 * 3600 * 1000).toJSON().substr(0, 19).replace('T', ' ');
              return reject({ msg: infoRes?.data?.expire_time ? `您已被禁止登录，解封时间：${newDate}` : '抱歉，您已被永久封号。' })
            }
          } else {
            reject({ msg: res.msg })
          }
          return resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  inviteUser({ commit }, params) {
    return new Promise((resolve, reject) => {
      inviteUser(params)
        .then(async (res) => {
          if (res.ret === 1) {
            const { data } = res;
            setToken(data.ticket);
            commit("SET_TOKEN", data.ticket);
          }
          return resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  setIsLogin({ commit }) {
    commit("SET_LOGINED", true);
  },
  setCookie({ commit }, cookie) {
    commit("SET_COOKIE", cookie);
  },
  setAccountInfo({ commit }, accountInfo) {
    commit("SET_ACCOUNT_INFO", accountInfo)
  },
  setUserstat({ commit }, userStat) {
    commit("SET_USERSTAT", userStat)
  },
  setQRcodeUrl({ commit }, qrcodeUrl) {
    commit("SET_QRCODEURL", qrcodeUrl)
  },
  setUserInfo({ commit }, user) {
    return new Promise((resolve) => {
      commit("SET_QQ", user.uin);
      setCookie("qq", user.uin);
      if (user.email) {
        commit("SET_EMAIL", user.email);
        setCookie("email", user.email);
      }
      if (user.avatar) {
        commit("SET_AVATAR", user.avatar);
        setCookie("avatar", user.avatar);
      }
      if (user.nickname) {
        commit("SET_NAME", user.nickname);
        setCookie("name", user.nickname);
      }
      if (user.id) {
        commit("SET_ID", user.id);
        setCookie("id", user.id);
      }
      if (user.uid) {
        commit("SET_UID", user.uid);
        setCookie("uid", user.uid);
      }
      if (user.username) {
        commit("SET_USERNAME", user.username);
        setCookie("username", user.username);
      }
      resolve();
    });
  },
  // get user info
  getInfo({ commit, state, dispatch }) {
    return new Promise(async (resolve, reject) => {
      if (!state.QQ) {
        const user = {
          uin: getCookie("qq"),
          avatar: localStorage.getItem('userAvatar'),
          nickname: localStorage.getItem('userName'),
          id: getCookie("id"),
          uid: getCookie("uid"),
          username: getCookie("username"),
          email: getCookie("email"),
        };
        await dispatch("user/setUserInfo", user, { root: true });
      }
      getUserAuth({})
        .then(async (res) => {
          const { data } = res;
          let auth = data.modules.split(",");
          commit("SET_AUTH", auth);
          commit("SET_ROLE", data.userRole);
          commit("SET_EMAIL", data.email);
          commit("SET_USERSTAT", data.userStat);
          commit("SET_QRCODEURL", data.qrcodeUrl);
          // const roles = [RoleMap[data.userRole]];
          // debugger
          // if (parseInt(data.roleid) === 147) {
          //   roles.push('admin');
          //   commit('SET_ADMIN', true);
          // } else {
          //   commit('SET_ADMIN', false);
          // }
          // if (parseInt(data.roleid) === 746) {
          //   roles.push('pmc');
          // }
          // commit('SET_LOGINED', true);
          resolve({ auth, userStat: data.userStat });
        })
        .catch((err) => {
          console.log(err);
          if (err.ret === -10003) {
            commit("SET_BINDING_RTX", true);
          }
          reject(err);
        });
    });
  },

  // user logout
  async getPlayTags({ commit }) {
    const { data, ret } = await getPlayTag({});
    if (ret == 1) {
      let result = data.playTagItems.filter(item => item.FatherId == 0)
      result.map(item => item.children = data.playTagItems.filter(child => child.FatherId == item.Id))
      const option = {
        ...data,
        playTagItemsChange: result
      }
      commit("SET_OPTIONS", option);
    } else {
      commit("SET_OPTIONS", {});
    }
  },

  // user logout
  logout({ commit, dispatch }) {
    return new Promise((resolve) => {
      rmLocalData(commit);
      dispatch("tagsView/delAllViews", null, { root: true });
      resolve();
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      rmLocalData(commit);
      resolve();
    });
  },

  setToken({ commit }, ticket) {
    setToken(ticket);
    commit("SET_TOKEN", ticket);
  },

  getMember({ commit, state }) {
    getMemberByUser({ user: state.QQ }).then((res) => {
      // console.log('getMemberByUser', res)
      const { data } = res;
      commit("SET_ROLE", data.userRole);
      let company = {};
      if (data.company) {
        company = data.company;
      }
      for (const c in company) {
        company[c].id = c;
      }
      commit("SET_COMPANYS", company);
    });
  },
};

function rmLocalData(commit) {
  vm.$aegis?.setConfig({
    uin:  999
  })

  commit("SET_QQ", "");
  commit("SET_NAME", "");
  commit("SET_UID", "");
  commit("SET_AVATAR", "");
  commit("SET_USERNAME", "");
  commit("SET_TOKEN", "");
  commit("SET_ROLES", []);
  commit("SET_AUTH", []);
  commit("SET_ROLE", 0);
  commit("SET_COMPANYS", []);
  commit("SET_PRODUCTS", []);
  commit("SET_ROLE_NAME", "");
  commit("SET_EN_NAME", "");
  commit("SET_EMAIL", "");
  commit("SET_LOGINED", false);
  removeToken();
  resetRouter();
  removeCookie("avatar");
  removeCookie("uid");
  removeCookie("username");
  removeCookie("name");
  removeCookie("qq");
  removeCookie("id");
  removeCookie("email");
  removeCookie("minicreate-Ticket");
  localStorage.removeItem('userInfo');
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
