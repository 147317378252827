/*
 * @Author       : lenchen
 * @Date         : 2020-06-23 11:29:03
 * @LastEditors  : lenchen
 * @LastEditTime : 2020-12-16 11:28:12
 * @FilePath     : \ihub\src\settings.js
 * @Description  :
 */
module.exports = {
  title: '轻游梦工坊官网',

  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: false,

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: false,

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production',
};
