import request from "@/utils/request";

// https://console-docs.apipost.cn/preview/4b7ee029c8760e2f/e220dbee4465a69e?target_id=71d32e6b-e76a-4411-b6de-aad1166a2c90 
/**
 * 一级标签列表
 * @param {*} params 
 * @returns 
 */
export function getFirstTagList(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=directory&ac=parenttag",
        method: "get",
        params
    });
}
/**
* 二级标签列表
* @param {*} params 
* @returns 
*/
export function getSecondTagList(params) {
   return request({
       url: "/community/custom.php?module=doc&mod=directory&ac=secondtag",
       method: "get",
       params
   });
}

/**
 * 文档专区页-标签列表&目录列表
 * @param {*} params 
 * @returns 
 */
export function getDocList(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=directory&ac=directorylist",
        method: "get",
        params
    });
}

/**
 * 文档专区页-获取文章
 * @param {*} params 
 * @returns 
 */
export function getArticle(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=directory&ac=article",
        method: "get",
        params
    });
}

/**
 * 搜索
 * @param {*} params 
 * @returns 
 */
export function keywordSearch(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=directory&ac=search",
        method: "get",
        params
    });
}

/**
 * 【需登录】根据标签和上级目录id查看目录
 * @param {*} params 
 * @returns 
 */
export function viewdirectory(params) {
    return request({
        url: "/community/custom.php?module=doc&mod=directory&ac=viewDirectory",
        method: "get",
        params
    });
}

/**
 * 【需登录】移动目录
 * @param {*} params 
 * @returns 
 */
export function movingDirectories(data) {
    return request({
        url: "/community/custom.php?module=doc&mod=directory&ac=moveDirectory",
        method: "post",
        data
    });
}

/**
 * 【需登录】预览文档根据文档id 
 * @param {*} params 
 * @param {*} ac //articlebycontent 预览根据修改内容  articlebyid 根据id
 * @returns 
 */
export function preview(data,ac) {
    return request({
        url: `/community/custom.php?module=doc&mod=preview&ac=${ac}`,
        method: "post",
        data
    });
}