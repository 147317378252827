import { asyncRoutes, constantRoutes } from '@/router';

/**
 * Use meta.role to determine if the current user has permission
 * @param auth
 * @param route
 */
function hasPermission(auth, route, role) {
  if (route.meta && route.meta.auth) {
    return auth.some(temp => route.meta.auth.includes(parseInt(temp)));
  }
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(parseInt(role));
  }
  return true;
}
// function hasPermission(auth, route) {
//   if (route.meta && route.meta.auth) {
//     // console.log(route.meta.auth, auth)
//     return auth.some(role => route.meta.auth.includes(parseInt(role)));
//   }
//   return true;
// }


/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param auth
 */
export function filterAsyncRoutes(routes, auth, role) {
  // console.log("filterAsyncRoutes", auth, role);
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasPermission(auth, tmp, role)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, auth, role);
      }
      res.push(tmp);
    }
  });

  return res;
}

const state = {
  routes: [],
  addRoutes: [],
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
};

const actions = {
  generateRoutes({ commit }, ar) {
    return new Promise((resolve) => {
      const accessedRoutes = filterAsyncRoutes(asyncRoutes, ...ar);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
