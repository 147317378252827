import * as user from "./user";
import * as project from "./project";
import * as document from "./document";
import * as videoSchool from "./videoSchool";

const apiList = {
  user,
  project,
  document,
  videoSchool
};

const install = (Vue) => {
  if (install.installed) return;
  install.installed = true;

  Object.defineProperties(Vue.prototype, {
    $api: {
      get() {
        return apiList;
      },
    },
  });
};

export default {
  install,
};
